<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Groups</h3>
            <hr class="m-t-0 m-b-40" />

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.name"
                  name="name"
                  required
                  type="text"
                  label="Name"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >&nbsp;</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="checkAll"
                        @click="checkAll()"
                        v-model="isCheckAll"
                      />
                      <label class="form-check-label" for="checkAll"
                        >Check All</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Drafting</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'drafting.read'"
                        :value="'drafting.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'drafting.read'"
                        >Membaca Drafting</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'drafting.create'"
                        :value="'drafting.create'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'drafting.create'"
                        >Mencatat Drafting</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'drafting.update'"
                        :value="'drafting.update'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'drafting.update'"
                        >Mengedit Drafting</label
                      >
                    </div>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Agenda Pimpinan</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'agenda.read'"
                        :value="'agenda.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'agenda.read'"
                        >Membaca Agenda Pimpinan</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Dokumen Pergub</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check from-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'docPergub.read'"
                        :value="'docPergub.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'docPergub.read'"
                        >Membaca Dokumen Pergub</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Surat Keluar</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_out.read'"
                        :value="'document_out.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'document_out.read'"
                        >Membaca Surat Keluar</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_out_all.read'"
                        :value="'document_out_all.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_out_all.read'"
                        >Membaca Surat Keluar Satu Unit</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_out.create'"
                        :value="'document_out.create'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_out.create'"
                        >Mencatat Surat Keluar</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_out.update'"
                        :value="'document_out.update'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_out.update'"
                        >Mengedit Surat Keluar</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_out_confirm.create'"
                        :value="'document_out_confirm.create'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_out_confirm.create'"
                        >Menyetujui Surat Keluar</label
                      >
                    </div>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Surat Masuk</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_in.read'"
                        :value="'document_in.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'document_in.read'"
                        >Membaca Surat Masuk</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_in_all.read'"
                        :value="'document_in_all.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_in_all.read'"
                        >Membaca Surat Masuk Satu Unit</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_in.create'"
                        :value="'document_in.create'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_in.create'"
                        >Mencatat Surat Masuk</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_in.update'"
                        :value="'document_in.update'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_in.update'"
                        >Mengedit Surat Masuk</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_in_confirm.create'"
                        :value="'document_in_confirm.create'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_in_confirm.create'"
                        >Melakukan Konfirmasi Surat Masuk</label
                      >
                    </div>
                    <br />
                    <!-- <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="Penerima Surat"
                        value="Penerima Surat"
                        v-model="payload.role"
                      />
                      <label class="form-check-label" for="Penerima Surat">Penerima Surat Masuk</label>
                    </div><br/> -->
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Disposisi</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'disposition_in.read'"
                        :value="'disposition_in.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'disposition_in.read'"
                        >Membaca Daftar Disposisi</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'disposition_in_all.read'"
                        :value="'disposition_in_all.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'disposition_in_all.read'"
                        >Membaca Daftar Disposisi Satu Unit</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'disposition_in.create'"
                        :value="'disposition_in.create'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'disposition_in.create'"
                        >Melakukan Disposisi</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'disposition_in.update'"
                        :value="'disposition_in.update'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'disposition_in.update'"
                        >Mengedit Disposisi</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >LAPORAN</label
                  >
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Surat Masuk</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'laporan_docin.read'"
                        :value="'laporan_docin.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'laporan_docin.read'"
                        >Membaca</label
                      >
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              <br />

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Disposisi Masuk</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'laporan_dispoin.read'"
                        :value="'laporan_dispoin.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'laporan_dispoin.read'"
                        >Membaca</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Surat Keluar</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'laporan_docout.read'"
                        :value="'laporan_docout.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'laporan_docout.read'"
                        >Membaca</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Surat Masuk Terproses</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'laporan_smt.read'"
                        :value="'laporan_smt.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'laporan_smt.read'"
                        >Membaca</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Disposisi Terproses</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check from-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'laporan_disposended.read'"
                        :value="'laporan_disposended.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'laporan_disposended.read'"
                        >Membaca</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >PENGATURAN</label
                  >
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Pengguna</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'user.create'"
                        :value="'user.create'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'user.create'"
                        >Menambah Pengguna</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'user.read'"
                        :value="'user.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'user.read'"
                        >Membaca Pengguna</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'user.update'"
                        :value="'user.update'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'user.update'"
                        >Edit Pengguna</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Role</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'role.create'"
                        :value="'role.create'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'role.create'"
                        >Menambah Role</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'role.read'"
                        :value="'role.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'role.read'"
                        >Membaca Role</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'role.update'"
                        :value="'role.update'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'role.update'"
                        >Mengedit Role</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Unit</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'unit.create'"
                        :value="'unit.create'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'unit.create'"
                        >Menambah Organisasi/Satker/Pokja</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'unit.read'"
                        :value="'unit.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'unit.read'"
                        >Membaca Organisasi/Satker/Pokja</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'unit.update'"
                        :value="'unit.update'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'units.update'"
                        >Mengedit Organisasi/Satker/Pokja</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Jabatan</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'position.create'"
                        :value="'position.create'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'position.create'"
                        >Menambah Jabatan</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'position.read'"
                        :value="'position.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'position.read'"
                        >Membaca Jabatan</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'position.update'"
                        :value="'position.update'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'position.update'"
                        >Mengedit Jabatan</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Jenis Surat</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_type.create'"
                        :value="'document_type.create'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_type.create'"
                        >Menambah Jenis Surat</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_type.read'"
                        :value="'document_type.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_type.read'"
                        >Membaca Jenis Surat</label
                      >
                    </div>
                    <br />
                    <div class="formm-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_type.update'"
                        :value="'document_type.update'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_type.update'"
                        >Mengedit Jenis Surat</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Klasifikasi Surat</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'clasification.create'"
                        :value="'clasification.create'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'clasification.create'"
                        >Menambah Klasifikasi Surat</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'clasification.read'"
                        :value="'clasification.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'clasification.read'"
                        >Membaca Klasifikasi Surat</label
                      >
                    </div>
                    <br />
                    <div class="formm-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'clasification.update'"
                        :value="'clasification.update'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'clasification.update'"
                        >Mengedit Klasifikasi Surat</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Klasifikasi Dokumen</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'clasification_doc.create'"
                        :value="'clasification_doc.create'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'clasification_doc.create'"
                        >Menambah Klasifikasi Dokumen</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'clasification_doc.read'"
                        :value="'clasification_doc.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'clasification_doc.read'"
                        >Membaca Klasifikasi Dokumen</label
                      >
                    </div>
                    <br />
                    <div class="formm-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'clasification_doc.update'"
                        :value="'clasification_doc.update'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'clasification_doc.update'"
                        >Mengedit Klasifikasi Dokumen</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Template Dokumen</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_template.create'"
                        :value="'document_template.create'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_template.create'"
                        >Menambah Template Dokumen</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_template.read'"
                        :value="'document_template.read'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_template.read'"
                        >Membaca Template Dokumen</label
                      >
                    </div>
                    <br />
                    <div class="formm-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'document_template.update'"
                        :value="'document_template.update'"
                        v-model="payload.permissions"
                      />
                      <label
                        class="form-check-label"
                        :for="'document_template.update'"
                        >Mengedit Template Dokumen</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Tipe Surat</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'type.create'"
                        :value="'type.create'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'type.create'"
                        >Menambah Tipe Surat</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'type.read'"
                        :value="'type.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'type.read'"
                        >Membaca Tipe Surat</label
                      >
                    </div>
                    <br />
                    <div class="formm-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'type.update'"
                        :value="'type.update'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'type.update'"
                        >Mengedit Tipe Surat</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Asal Surat</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'origin.create'"
                        :value="'origin.create'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'origin.create'"
                        >Menambah Asal Surat</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'origin.read'"
                        :value="'origin.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'origin.read'"
                        >Membaca Asal Surat</label
                      >
                    </div>
                    <br />
                    <div class="formm-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'origin.update'"
                        :value="'origin.update'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'origin.update'"
                        >Mengedit Asal Surat</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3"
                    >Layanan Informasi</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'questions.create'"
                        :value="'questions.create'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'questions.create'"
                        >Menambah Layanan Informasi</label
                      >
                    </div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'questions.read'"
                        :value="'questions.read'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'questions.read'"
                        >Membaca Layanan Informasi</label
                      >
                    </div>
                    <br />
                    <div class="formm-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'questions.update'"
                        :value="'questions.update'"
                        v-model="payload.permissions"
                      />
                      <label class="form-check-label" :for="'questions.update'"
                        >Mengedit Layanan Informasi</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3">Pengaturan</label>
                  <div class="col-md-9 col-xs-9">
                    
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'units.create'"
                        :value="'units.create'"
                      />
                      <label class="form-check-label" :for="'units.create'">Menambah Organisasi/Satker/Pokja</label>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="row">
              <div class="col-md-8">
                <!-- <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3">Surat Keluar</label>
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="listCheck"
                        value="document_out"
                      />
                      <label class="form-check-label">Mencatat Surat Keluar</label>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3">Surat Masuk</label>
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="listCheck"
                        value="document_in"
                      />
                      <label class="form-check-label">Mencatat Surat Masuk</label>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3">Disposisi</label>
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="listCheck"
                        value="disposition_in"
                      />
                      <label class="form-check-label">Melakukan Disposisi</label>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>

            <!-- <div class="row"> -->
            <!-- <div class="col-md-8"> -->
            <!-- <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3">Membuat Surat</label>
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="listCheck"
                        value="document_out"
                      >
                      <label class="form-check-label">Membuat Surat</label>
                    </div>
                  </div>
                </div> -->
            <!-- <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3">Menerima Surat</label>
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="listCheck"
                        value="document_in"
                      >
                      <label class="form-check-label">Menerima Surat</label>
                    </div>
                  </div>
                </div> -->
            <!-- <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3">Disposisi</label>
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-chechk-inline">
                      <input
                        type="checkbox"
                        class="forn-check-input"
                        v-model="listCheck"
                        value="disposition_in"
                      />
                      <label class="form-check-labek">Melakukan Disposisi</label>
                    </div>
                  </div>
                </div> -->
            <!-- <div class="form-group row">
                  <label class="control-label text-right col-md-3 col-xs-3">Referensi</label>
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="listCheck"
                        value="unit,user,redaksi,room,tracking,groups,clasification_dok,clasification_doc"
                      >
                      <label class="form-check-label">Referensi</label>
                    </div>
                  </div>
                </div> -->
            <!-- </div> -->

            <!-- <div class="col-md-8">
                <div
                  class="form-group row"
                  v-for="(permission, index) in permissions"
                  v-bind:key="permission.create + index"
                >
                  <label
                    class="control-label text-right col-md-3 col-xs-3"
                  >{{ convertNormalCase(index) }}</label>
                  <div class="col-md-9 col-xs-9">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="index + '.create'"
                        :value="index + '.create'"
                        v-model="payload.permissions"
                      >
                      <label class="form-check-label" :for="index + '.create'">Create</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="index + '.read'"
                        :value="index + '.read'"
                        v-model="payload.permissions"
                      >
                      <label class="form-check-label" :for="index + '.read'">Read</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="index + '.update'"
                        :value="index + '.update'"
                        v-model="payload.permissions"
                      >
                      <label class="form-check-label" :for="index + '.update'">Update</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="index + '.delete'"
                        :value="index + '.delete'"
                        v-model="payload.permissions"
                      >
                      <label class="form-check-label" :for="index + '.delete'">Delete</label>
                    </div>
                  </div>
                </div>
              </div>
               -->
            <!-- </div> -->
          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button
                      type="submit"
                      class="btn btn-success"
                      title="Submit"
                    >
                      Submit</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                      title="Cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.groups;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        name: "",
        permissions: [],
        role: false,
      },
      listCheck: [],
      isDisabled: false,
      permissions: [],
      addPermissions: [],
      isCheckAll: false,
    };
  },
  mounted() {
    this.permissions = this.$store.state.profile.permissions;
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/groups/${this.$route.params.id}`).then((response) => {
            const state = {
              loaded: true,
            };
            this.$store.commit("groups/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("groups/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        name: data.name,
        permissions: data.permission,
      };
    },
    convertNormalCase(val) {
      return val.replace(/_|-]/g, " ");
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.payload.permissions = [];
      if (this.isCheckAll) {
        for (var key in this.permissions) {
          this.payload.permissions.push(
            key + ".create",
            key + ".update",
            key + ".read",
            key + ".delete"
          );
        }
      }
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("groups/onCancel");
    },
    submit() {
      const payload = {
        name: this.payload.name,
        permissions: this.payload.permissions,
        list: this.permissions,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("groups/submitEdit", {
              data,
              id: this.$route.params.id,
            });
          } else {
            this.$store.dispatch("groups/submitAdd", data);
          }
        }
      });
    },
    getLang(id) {
      return this.$store.state.groups.lang[id];
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

